<template>
  <section>
    <header class="my-2">
      <h1 class="text-3xl">Ventas pendientes de pago</h1>

      <div>
        <p>
          Aquí podrás ver las compras que tus clientes han realizado y que aún
          no han pagado.
        </p>
      </div>
    </header>

    <orders-filters
      :folio="true"
      :from_store_name="true"
      :store_name="true"
      :created_at="true"
      :delivery_date="true"
      :loading="loading"
      @applyFilters="$emit('applyFilters')"
      @resetFilters="$emit('resetFilters')"
    />

    <div v-if="!loading">
      <pending-sales-table
        :tableItems="tableItems"
        :onClickPay="(id) => $emit('onClickPay', id)"
      />

      <div v-if="pendingOrdersPagination.total_pages >= 1" class="mt-2">
        <Pagination
          :loading="loading"
          :handlePagination="(page) => $emit('handlePagination', page)"
          :pagination="pendingOrdersPagination"
          :hideEntriesPerPage="true"
        />
      </div>
      <div v-else>
        <b-alert show variant="warning" class="p-4">
          No hay ventas pendientes de pago
        </b-alert>
      </div>
    </div>
    <div v-else>
      <b-skeleton-table :rows="10" :columns="7" />
    </div>
  </section>
</template>

<script>
import OrdersFilters from "@/@core/components/orders-filters.vue"
import PendingSalesTable from "./pending-sales-table.vue"
import Pagination from "@/@core/components/Pagination.vue"

export default {
  components: {
    OrdersFilters,
    PendingSalesTable,
    Pagination,
  },
  props: {
    loading: Boolean,
    tableItems: Array,
    pendingOrdersPagination: Object,
  },
}
</script>
