<template>
  <div>
    <div class="filters__container">
      <div v-if="folio">
        <b-form-group label="Folio" class="mb-0">
          <b-form-input
            v-model="filters.folio"
            placeholder="Buscar por folio"
            @input="applyFilters"
          ></b-form-input>
        </b-form-group>
      </div>
      <div v-if="from_store_name">
        <b-form-group label="Cliente" class="mb-0">
          <b-form-input
            v-model="filters.from_store_name"
            placeholder="Buscar por cliente"
            @input="applyFilters"
          ></b-form-input>
        </b-form-group>
      </div>
      <div v-if="store_name">
        <b-form-group label="Vendedor" class="mb-0">
          <b-form-input
            v-model="filters.store_name"
            placeholder="Buscar por vendedor"
            @input="applyFilters"
          ></b-form-input>
        </b-form-group>
      </div>
      <div v-if="created_at">
        <b-form-group label="Fecha de compra" class="mb-0">
          <b-form-datepicker
            v-model="filters.created_at"
            locale="es"
            placeholder="Fecha de compra"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            @input="applyFilters"
          ></b-form-datepicker>
        </b-form-group>
      </div>
      <div v-if="delivery_date">
        <b-form-group label="Fecha de entrega" class="mb-0">
          <b-form-datepicker
            v-model="filters.delivery_date"
            locale="es"
            placeholder="Fecha de entrega"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            @input="applyFilters"
          ></b-form-datepicker>
        </b-form-group>
      </div>
    </div>
    <div class="d-flex py-2 w-100 gap-2">
      <b-button
        variant="outline-secondary"
        :disabled="loading"
        @click="resetFilters()"
      >
        Limpiar filtros
      </b-button>
      <b-button variant="primary" @click="applyFilters" :disabled="loading">
        Aplicar filtros
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folio: {
      type: Boolean,
      default: false,
    },
    from_store_name: {
      type: Boolean,
      default: false,
    },
    store_name: {
      type: Boolean,
      default: false,
    },
    created_at: {
      type: Boolean,
      default: false,
    },
    delivery_date: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      },
    }
  },
  methods: {
    applyFilters() {
      this.$emit("applyFilters", this.filters)
    },
    resetFilters() {
      this.filters = {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      }
      this.$emit("applyFilters", this.filters)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  align-items: center;
}
</style>
