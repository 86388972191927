<template>
  <b-table hover responsive :items="tableItems" :fields="fields">
    <template #cell(store_name)="data">
      {{ data.value }}
    </template>
    <template #cell(created_at)="data">
      {{ data.value | dateNtime2 }}
    </template>

    <template #cell(total)="data"> ${{ data.value | money }} </template>

    <template #cell(delivery_date)="data">
      <template v-if="data.value">
        {{ data.value | dateNtime2 }}
      </template>
      <template v-else>
        <span class="text-danger">No asignada</span>
      </template>
    </template>

    <template #cell(actions)="data">
      <b-dropdown variant="link" no-caret>
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'order-view', params: { id: data.item.id } }"
        >
          Ver orden
        </b-dropdown-item>

        <b-dropdown-item @click="onClickPay(data.item)">
          Marcar como pagado
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <template #cell(details)="data">
      <div class="text-nowrap">
        <b-button
          :to="{ name: 'order-view', params: { id: data.item.id } }"
          :variant="'primary'"
          size="sm"
        >
          Ver
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    tableItems: {
      type: Array,
      required: true,
    },
    onClickPay: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "folio",
          label: "Folio",
        },
        {
          key: "from_store_name",
          label: "Cliente",
        },
        {
          key: "store_name",
          label: "Vendedor",
        },
        {
          key: "created_at",
          label: "Fecha de compra",
        },
        {
          key: "delivery_date",
          label: "Fecha de entrega",
        },
        {
          key: "total",
          label: "Total",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    }
  },
}
</script>
