import { render, staticRenderFns } from "./pending-purchases-tab.vue?vue&type=template&id=e75416b0&"
import script from "./pending-purchases-tab.vue?vue&type=script&lang=js&"
export * from "./pending-purchases-tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports