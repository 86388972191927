var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filters__container"},[(_vm.folio)?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Folio"}},[_c('b-form-input',{attrs:{"placeholder":"Buscar por folio"},on:{"input":_vm.applyFilters},model:{value:(_vm.filters.folio),callback:function ($$v) {_vm.$set(_vm.filters, "folio", $$v)},expression:"filters.folio"}})],1)],1):_vm._e(),(_vm.from_store_name)?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Cliente"}},[_c('b-form-input',{attrs:{"placeholder":"Buscar por cliente"},on:{"input":_vm.applyFilters},model:{value:(_vm.filters.from_store_name),callback:function ($$v) {_vm.$set(_vm.filters, "from_store_name", $$v)},expression:"filters.from_store_name"}})],1)],1):_vm._e(),(_vm.store_name)?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Vendedor"}},[_c('b-form-input',{attrs:{"placeholder":"Buscar por vendedor"},on:{"input":_vm.applyFilters},model:{value:(_vm.filters.store_name),callback:function ($$v) {_vm.$set(_vm.filters, "store_name", $$v)},expression:"filters.store_name"}})],1)],1):_vm._e(),(_vm.created_at)?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Fecha de compra"}},[_c('b-form-datepicker',{attrs:{"locale":"es","placeholder":"Fecha de compra","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},on:{"input":_vm.applyFilters},model:{value:(_vm.filters.created_at),callback:function ($$v) {_vm.$set(_vm.filters, "created_at", $$v)},expression:"filters.created_at"}})],1)],1):_vm._e(),(_vm.delivery_date)?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Fecha de entrega"}},[_c('b-form-datepicker',{attrs:{"locale":"es","placeholder":"Fecha de entrega","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},on:{"input":_vm.applyFilters},model:{value:(_vm.filters.delivery_date),callback:function ($$v) {_vm.$set(_vm.filters, "delivery_date", $$v)},expression:"filters.delivery_date"}})],1)],1):_vm._e()]),_c('div',{staticClass:"d-flex py-2 w-100 gap-2"},[_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Limpiar filtros ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.applyFilters}},[_vm._v(" Aplicar filtros ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }