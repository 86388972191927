<template>
  <main>
    <b-card>
      <b-tabs>
        <b-tab title="Ventas pendientes">
          <pending-sales-tab
            :loading="loading"
            :tableItems="pendingOrders"
            :pendingOrdersPagination="pendingOrdersPagination"
            @applyFilters="applyFilters"
            @resetFilters="resetFilters"
            @handlePagination="handlePendingOrdersPagination"
            @onClickPay="onClickPay"
          />
        </b-tab>
        <b-tab title="Compras pendientes">
          <pending-purchases-tab
            :loading="purchasesLoading"
            :tableItems="purchasesPendingOrders"
            :pendingOrdersPagination="purchasesPendingOrdersPagination"
            @applyFilters="applyPurchasesFilters"
            @resetFilters="resetPurchasesFilters"
            @handlePagination="handlePurchasesPendingOrdersPagination"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import { mapActions } from "vuex"
import cartMixin from "../../@core/mixins/cartMixin"
import PaymentMethodsModal from "@core/components/checkout/payment-methods-modal.vue"

import CodiInfoModal from "@/@core/components/WallEateCommerce/CodiInfoModal.vue"
import CodiPaymentModal from "@core/components/checkout/codi-payment-modal.vue"
import { handleAPIErrors } from "../../@core/utils/fetch-utils"
import messagesMixin from "../../@core/mixins/messagesMixin"
import debounce from "lodash/debounce"
import PendingSalesTab from "./pending-sales-tab.vue"
import PendingPurchasesTab from "./pending-purchases-tab.vue"

export default {
  mixins: [cartMixin, messagesMixin],
  components: {
    PendingSalesTab,
    PendingPurchasesTab,

    PaymentMethodsModal,
    CodiInfoModal,
    CodiPaymentModal,
  },
  data() {
    const establishmentId = this.$route.params.id

    return {
      establishmentId,
      pendingOrders: [],
      pendingOrdersPagination: {},
      selectedOrder: null,
      acceptedPaymentTypes: [],
      codiQrcode: null,
      loading: false,
      filters: {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      },

      userData: JSON.parse(localStorage.getItem("userData")),

      purchasesLoading: false,
      purchasesPendingOrders: [],
      purchasesPendingOrdersPagination: {},
      purchasesFilters: {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      },
    }
  },
  created() {
    Promise.allSettled([
      this.getPendingOrders(),
      this.getPurchasesPendingOrders(),
    ])
  },
  methods: {
    ...mapActions("pendingOrders", [
      "fetchPendingOrdersReceived",
      "processOrder",
      "fetchPendingOrdersMade",
    ]),

    applyFilters(filters) {
      this.filters = { ...filters }
      this.loading = true
      this.debounceFilters()
    },

    debounceFilters: debounce(function () {
      this.getPendingOrders()
    }, 500),

    resetFilters(filters) {
      this.filters = {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      }
      this.getPendingOrders()
    },

    async getPendingOrders({ page } = {}) {
      try {
        this.loading = true
        const response = await this.fetchPendingOrdersReceived({
          by_active_status: true,
          by_payment_status: "pending",
          by_store: this.$route.params.id,
          by_buy_order: true,
          by_folio: this.filters.folio,
          by_from_store_name: this.filters.from_store_name,
          by_store_name: this.filters.store_name,
          by_created_at: this.filters.created_at,
          by_delivery_date: this.filters.delivery_date,
          meta: {
            pagination: {
              page: page || 1,
            },
          },
        })
        this.pendingOrders = []
        this.pendingOrdersPagination = response.meta.pagination
        response.data.forEach((order) => {
          const orderData = order.pending_order
          this.pendingOrders.push({
            ...orderData,
            ...order,
            store_name: order.store_name,
            folio: order.folio,
            products: order.products,
            from_store_name: order.from_store_name,
            from_store_accepted_payments: order.from_store_accepted_payments,
          })
        })
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)
        this.errorToast(errors[0])
      } finally {
        this.loading = false
      }
    },

    async handlePendingOrdersPagination({ page }) {
      await this.getPendingOrders({ page })
    },

    applyPurchasesFilters(filters) {
      this.filters = { ...filters }
      this.loading = true
      this.debounceGetPurchasesOrders()
    },

    debounceGetPurchasesOrders: debounce(function () {
      this.getPurchasesPendingOrders()
    }, 500),

    resetPurchasesFilters(filters) {
      this.purchasesFilters = {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      }
      this.getPurchasesPendingOrders()
    },

    async getPurchasesPendingOrders({ page } = {}) {
      try {
        this.purchasesLoading = true
        const response = await this.fetchPendingOrdersMade({
          by_active_status: true,
          by_payment_status: "pending",
          by_from_store: this.$route.params.id,
          by_folio: this.purchasesFilters.folio,
          by_store_name: this.purchasesFilters.store_name,
          by_created_at: this.purchasesFilters.created_at,
          by_delivery_date: this.purchasesFilters.delivery_date,
          by_from_store_name: this.purchasesFilters.from_store_name,
          meta: {
            pagination: {
              page: page || 1,
            },
          },
        })
        this.purchasesPendingOrders = []
        this.purchasesPendingOrdersPagination = response.meta.pagination
        response.data.forEach((order) => {
          const orderData = order.pending_order
          this.purchasesPendingOrders.push({
            ...orderData,
            ...order,
            store_name: order.store_name,
            folio: order.folio,
            products: order.products,
            from_store_name: order.from_store_name,
            from_store_accepted_payments: order.from_store_accepted_payments,
          })
        })
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)
        this.errorToast(errors[0])
      } finally {
        this.purchasesLoading = false
      }
    },

    async handlePurchasesPendingOrdersPagination({ page }) {
      await this.getPurchasesPendingOrders({ page })
    },

    async onClickPay(order) {
      this.$swal({
        title: `Estas seguro de pagar la compra con folio ${order.folio}?`,
        text: `Se pagara la compra, no se podrá deshacer esta acción`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, pagar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true
            const res = await this.processOrder({
              id: order.id,
            })

            this.getPendingOrders()

            this.successSwal({
              title: "Compra pagada",
              text: "La compra con folio " + res.folio + " ha sido pagada",
            })
          } catch (e) {
            const errors = handleAPIErrors(e.response.data)

            this.showErrorSwal({
              text: errors[0],
            })
          } finally {
            this.loading = false
          }
        }
      })

      this.$bvModal.show("payment-methods-modal")
      this.selectedOrder = order
      this.acceptedPaymentTypes = order.from_store_accepted_payments
    },
  },
}
</script>

<style lang="scss" scoped></style>
